<template>
  <div class="tw-h-full tw-w-full tw-flex tw-justify-center">
    <SignUpComponent />
  </div>
</template>

<script>
import SignUpComponent from '@/views/auth/SignUp/SignUpComponent';

export default {
  components: {
    SignUpComponent,
  },
};
</script>

<style>
</style>
